import { useEffect, useRef, useState } from 'react';

function InputWithLabel(props) {
  return (
    <div>
      <label
        htmlFor={props.name}
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div className="mt-1">
        <input
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          type={props.type ?? 'text'}
          name={props.name}
          id={props.name}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}

function useInputWithLabel({
  type,
  name,
  placeholder,
  label,
  errorMessage,
  errorCheck = null,
  focusFirst = false,
  disabled = false,
  initialValue = '',
}) {
  const [value, setValue] = useState(initialValue);
  const [blur, setBlur] = useState(false);
  const [error, setError] = useState(errorCheck != null);
  const inputReference = useRef(null);

  useEffect(() => {
    if (focusFirst) {
      inputReference.current.focus();
    }
  }, [inputReference]);

  useEffect(() => {
    if (!errorCheck) return;
    setError(errorCheck(value));
  }, [value]);

  const input = (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          disabled={disabled}
          onBlur={() => setBlur(true)}
          ref={inputReference}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            // checkError();
          }}
          type={type}
          name={name}
          id={name}
          className={
            !!errorCheck && error && blur
              ? `block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md`
              : `shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md`
          }
          placeholder={placeholder}
        />
      </div>
      {!!errorCheck && error && blur && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </div>
  );
  return [value, input, !error, setValue];
}

export { useInputWithLabel, InputWithLabel };
