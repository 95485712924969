import FilledButton from '../../components/buttons/FilledButton';
import { useInputWithLabel } from '../../components/inputs/InputWithLabel';
import { useNavigate,useLocation } from 'react-router';
import { EMAIL_CONST } from '../../constants';

function LoginTabView({ params }) {
  const search = useLocation().search;
  const apiKey = params.key;
  const id = params.id;
  const authStatus = new URLSearchParams(search).get("auth") ?? 1;

  const navigate = useNavigate();

  // const [email, emailInput, emailIsValid] = useInputWithLabel({
  //   type: 'email',
  //   name: 'email',
  //   placeholder: 'Enter your email',
  //   label: 'Email',
  //   errorMessage: 'Please enter a valid email.',
  //   focusFirst: true,
  // });

  const [mobileNumber, mobileNumberInput, mobileIsValid] = useInputWithLabel({
    type: 'number',
    name: 'mobileNumber',
    placeholder: 'Enter your mobile number',
    label: 'Mobile Number',
    errorMessage: 'Please enter a valid 8 digit mobile number.',
    errorCheck: (value) => {
      return value.length != 8;
    },
  });
  const onSubmit = () => {
    // e.preventDefault();
    console.log(mobileNumber);
    localStorage.setItem('api-key', apiKey);
    localStorage.setItem('reloadCount', 0);
    navigate('/web/otp', {
      state: {
        key: apiKey,
        id: id,
        mobileNumber: mobileNumber,
        email: EMAIL_CONST,
        status: authStatus
      }
    })

  }
  return (
    <div className="flex-col">
      <form>
        <div className="px-4 py-5 sm:p-6 space-y-4">
          {/* <div>{emailInput}</div> */}
          {mobileNumberInput}
        </div>
        <div className="px-4 py-4 sm:px-6">
          <div className="flex justify-center">
            <FilledButton
              type="submit"
              className="w-full"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              disabled={
                !mobileIsValid
                // || !emailIsValid
              }

            >
              Link
            </FilledButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export { LoginTabView };
