import React from 'react';

const Ctx = React.createContext();

// Styled Components
// ==============================

const ToastContainer = (props) => (
  <div style={{ position: 'fixed', right: 10, top: 10, left: 10 }} {...props} />
);
const Toast = ({ children, onDismiss, type }) => (
  <div
    onClick={onDismiss}
    className={`${
      type == 'success'
        ? 'bg-green-600 text-white'
        : type == 'error'
        ? 'bg-red-600 text-white'
        : 'bg-gray-600'
    }  rounded-md font-medium text-sm px-4 py-2`}
    role="alert"
  >
    {children}
  </div>
);

// Provider
// ==============================

let toastCount = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const addToast = (content, type = 'success') => {
    const id = toastCount++;
    const toast = { content, id, type };
    setToasts([...toasts, toast]);

    setTimeout(() => {
      removeToast(id);
    }, 1500);
  };

  const removeToast = (id) => {
    const newToasts = toasts.filter((t) => t.id !== id);
    setToasts(newToasts);
  };
  // avoid creating a new fn on every render
  const onDismiss = (id) => () => removeToast(id);

  return (
    <Ctx.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer>
        {toasts.map(({ content, id, type, ...rest }) => (
          <Toast
            key={id}
            Toast={Toast}
            type={type}
            onDismiss={onDismiss(id)}
            {...rest}
          >
            {content}
          </Toast>
        ))}
      </ToastContainer>
    </Ctx.Provider>
  );
}

// Consumer
// ==============================

export const useToasts = () => React.useContext(Ctx);
