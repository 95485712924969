import Spinner from '../spinners/Spinner';

export default function FilledButton(props) {
  return (
    <button
      disabled={props.disabled ?? false}
      onClick={(e) => props.onClick(e)}
      type={props.type ?? 'button'}
      className={`${props.className} flex justify-center inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500  disabled:opacity-50`}
    >
      {props.isLoading ? <Spinner className="w-4 h-4" /> : null}

      {props.children}
    </button>
  );
}
