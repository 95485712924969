import React, { useEffect } from 'react';
import { LoginTabView } from './components/LoginView';
// import PageHeader from '../../components/header/PageHeader';
// import { useLocation } from 'react-router';
import { useParams } from "react-router-dom";
import { initializeApp } from 'firebase/app'
import firebaseConfig from "../firebase"
export default function Login() {
  const routeParams = useParams();
  useEffect(() => {
    // localStorage.clear();
    initializeApp(firebaseConfig)
  }, []);

  return (
    <div className="p-4 pt-20 flex justify-center">
      <div className="space-y-4">
        <p className="text-md font-semibold">
          Open API Pass
        </p>
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div className="px-1 py-5 sm:px-6">
            <div className="flex justify-center">
              <LoginTabView params={routeParams} />
            </div>
            <p>Empowered by Open API Pass Foundation</p>
          </div>
        </div>
      </div>
    </div>

  );
}
