var EMAIL_CONST = 'weblink@openapipass.org';

var LINK_ERROR_MESSAGE = "Error linking to H365. Ensure that you have downloaded and login to Partner's mobile app before linking."
//PROD

var SSO_BASE_URL = 'https://sso.openapipass.org/api/v1';

// STG
// var SSO_BASE_URL = 'https://stg.sso.openapipass.org/api/v1';

//DEV
// var SSO_BASE_URL = 'http://localhost:8080/api/v1';

export {SSO_BASE_URL, EMAIL_CONST, LINK_ERROR_MESSAGE}
