import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react';
import Login from './views/Login';
const SuccessPage = React.lazy(() => import('./components/pages/Success'));
const Otp = React.lazy(() => import('./views/Otp'));

class App extends Component {

  render() {
    document.title = "Open API Pass";
    return (
      <Suspense fallback={<><div>...Loading</div></>}>
        <NiceModal.Provider>

          <BrowserRouter>
            <Routes>
              <Route exact path="/web/login/:id/:key" name="Login Page" element={<Login />} />
              <Route path="/web/otp" name="Otp Page" element={<Otp />} />
              <Route path="/web/Success" name="Link Success" element={<SuccessPage />} />
            </Routes>
          </BrowserRouter>
        </NiceModal.Provider>
      </Suspense>
    );
  }
}

export default App;
